import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import Button from "../../../components/custom-widgets/button.js";
import List from "../../../components/custom-widgets/list.js";
import BusinessServicesCards from "../../../components/business-banking/BusinessServicesCards.js";

// Helper Functions
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
const CashFlowManagement = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/business-banking/business-services/cash-flow-management/hero-cashflow-management-102523-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/business-banking/business-services/cash-flow-management/hero-cashflow-management-102523-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/business-banking/business-services/cash-flow-management/hero-cashflow-management-102523-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/business-banking/business-services/cash-flow-management/hero-cashflow-management-102523-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/business-banking/business-services/cash-flow-management/hero-cashflow-management-102523-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/business-banking/business-services/cash-flow-management/hero-cashflow-management-102523-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/business-banking/business-services/cash-flow-management/hero-cashflow-management-102523-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
      dashboardImage: file(relativePath: { eq: "finsync-cashflow.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  `);

  const heroChevronData = {
    id: "cash-flow-management-hero",
    ...getHeroImgVariables(imgData),
    altText: "Business owner and employee in a shop reviewing financials.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Quickly anticipate expenses so you can react to anything"
          }
        },
        {
          id: 2,
          button: {
            id: "cash-flow-management-hero-cta",
            text: "Take Control of Your Cashflow",
            url: "https://www.finsync.com/wafdbank/",
            class: "btn-white",
            externalLink: true,
            target: "blank"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/business-banking",
      title: "Business Banking"
    },
    {
      id: 2,
      url: "/business-banking/business-services",
      title: "Business Services"
    },
    {
      id: 3,
      active: true,
      title: "Cash Flow Management"
    }
  ];

  const seoTitle = "Cash Flow Management Tools - FINSYNC";
  const seoDesc =
    "Gain more control over your cash flow, allowing you to seize growth opportunities to scale up your business and minimize financial risks at WaFd Bank.";
  const SEOData = {
    title: seoTitle,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: seoTitle
      },
      {
        name: "description",
        content: seoDesc
      },
      {
        property: "og:title",
        content: seoTitle
      },
      {
        property: "og:description",
        content: seoDesc
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/business-banking/business-services/cash-flow-management"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-cashflow-management-102523.jpg"
      }
    ]
  };

  const listData = {
    accountName: "cash-flow-management-benefits-list",
    greenCircleCheckmark: true,
    items: [
      {
        id: 1,
        text: "User-friendly, especially when connected with your WaFd business accounts"
      },
      {
        id: 2,
        text: "See all bank and credit card activity in one place"
      },
      {
        id: 3,
        text: "Visualize business cash flow with projections so you can plan for seasonality"
      },
      {
        id: 4,
        text: "Plan for upcoming income and expenses with intuitive charts and calendars"
      },
      {
        id: 5,
        text: "Better predict when funds will move into and out of the business"
      },
      {
        id: 6,
        text: "Track profitability in real time"
      },
      {
        id: 7,
        text: "Training and support resources are available with dedicated support representatives based in the US"
      }
    ]
  };

  const claimBtn = {
    id: "claim-your-savings-cta",
    text: "Claim your savings today",
    containerClass: "w-100 w-md-auto",
    class: "btn-white d-block",
    url: "https://www.finsync.com/wafdbank/",
    externalLink: true,
    target: "blank",
    showIcon: false
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Cash Flow Management</h1>
        <div className="row">
          <div className="col-md-6">
            <p>
              Gain more control over your cash flow, allowing you to seize growth opportunities to scale up your
              business and minimize financial risks, like economic downturns or late payments, to help your business
              stand the test of time.
            </p>
            <h4 className="font-weight-semi-bold text-green-60">Benefits of FINSYNC:</h4>
            <List {...listData} />
          </div>
          <div className="col-md-6">
            <GatsbyImage
              image={imgData.dashboardImage.childImageSharp.gatsbyImageData}
              alt="Finsync cash flow dashboard"
            />
          </div>
        </div>
      </section>
      <section className="bg-green-60">
        <div className="container d-flex flex-column flex-md-row justify-content-center justify-content-md-start align-items-center">
          <h4 className="text-white font-weight-bold mb-md-0 mr-3">
            As a client of WaFd Bank, join the FINSYNC network for free!
          </h4>
          <Button {...claimBtn} />
        </div>
      </section>
      <section className="container">
        <BusinessServicesCards servicesArray={[4, 6]} />
      </section>
    </SecondaryLanding>
  );
};

export default CashFlowManagement;
